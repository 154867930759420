$(function () {
    function scrollMaterialList() {
        $('.materials-group').show();
        const width = $('.materials-group').width();
        $('.materials-group').slick({
            infinite: true,
            slidesToShow: width / 530,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 1
                    }
                }]
        });
    }
    scrollMaterialList();
    window.addEventListener('resize', () => {
        $('.materials-group').slick('unslick');
        scrollMaterialList();
    });
});
