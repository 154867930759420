window.addEventListener('DOMContentLoaded', () => {
  const pcNav = document.querySelector('[data-nav="js-header-menu"]');
  const spNav = document.querySelector('[data-nav="js-header-menu--sp"]');
  const spToggle = document.querySelector('[data-toggle="js-header-toggle"]');
  const cvButton = document.querySelector('[data-btn="js-cv-button"]');
  const headerLinks = document.querySelectorAll('[data-link="js-header-link"]');
  const tabWidth = window.matchMedia('(max-width: 1024px)');

  tabWidth.matches ? spNav.appendChild(pcNav).classList.add('header-menu--sp') : false;
  tabWidth.matches ? spNav.appendChild(cvButton) : false;

  spToggle.addEventListener('click', () => {
    document.body.classList.toggle('is-open');
  });

  headerLinks.forEach((headerLink) => {
    headerLink.addEventListener('click', () => {
      document.body.classList.remove('is-open');
    });
  });
});
