window.addEventListener('DOMContentLoaded', () => {
  const pcMediaQuery = window.matchMedia('(max-width: 1280px) and (min-width:1025px)');
  const spMediaQuery = window.matchMedia('(max-width: 375px)');
  const pcBaseW = 1280;
  const spBaseW = 375;
  let iOSviewportW = 0;
  const ua = navigator.userAgent.toLowerCase();
  const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipod') > -1 || ua.indexOf('ipad') > -1;

  isiOS ? (iOSviewportW = document.documentElement.clientWidth) : false;

  const updateMetaViewport = () => {
    let viewportContent;
    let w = window.outerWidth;
    isiOS ? (w = iOSviewportW) : false;
    if (pcMediaQuery.matches) {
      w < pcBaseW
        ? (viewportContent = `width=${pcBaseW}px,user-scalable=no,shrink-to-fit=yes`)
        : (viewportContent = 'width=device-width,user-scalable=no,shrink-to-fit=yes');
    } else if (spMediaQuery.matches) {
      w < spBaseW
        ? (viewportContent = `width=${spBaseW}px,user-scalable=no,shrink-to-fit=yes`)
        : (viewportContent = 'width=device-width,user-scalable=no,shrink-to-fit=yes');
    } else {
      viewportContent = 'width=device-width,user-scalable=no,shrink-to-fit=yes';
    }
    document.querySelector('meta[name="viewport"]').setAttribute('content', viewportContent);
  };

  window.addEventListener('resize', updateMetaViewport, false);
  window.addEventListener('orientationchange', updateMetaViewport, false);

  const ev = document.createEvent('UIEvent');
  ev.initEvent('resize', true, true);
  window.dispatchEvent(ev);
});
